@import 'scss/mixins/text';
@import 'scss/mixins/spacing';
@import 'scss/var/color';
@import 'scss/functions/text';

.-text-link {
  color: $link-color;
  text-decoration: underline;
}

.-text-uppercase {
  text-transform: uppercase;
}

.-text-green-color {
  color: $active-approved-labels-color;
}

.-text-red-color {
  color: $rejected-declined-labels-color;
}

.-text-bold {
  font-weight: bold;
}

.-text-label {
  display: block;
  color: $table-headers-color;

  @include setSpace(margin-bottom, 5);
  @include setFontSize(12);
}

.-text-content {
  color: $base-font-color;
  word-break: break-all;
}

.-text-content-link {
  color: $base-font-color;
  word-break: break-all;
  &:hover {
    cursor: pointer;
  }
}

.-text-status {
  @include fontMedium;
  @include setFontSize(14);

  &.-active,
  &.-paid,
  &.-success,
  &.-approved,
  &.-generated,
  &.-re-recon,
  &.-completed {
    color: $active-approved-labels-color;
  }
  &.-pending,
  &.-id_review,
  &.-manual_check,
  &.-untagged,
  &.-overdue,
  &.-scheduled,
  &.-frozen,
  &.-frozen_pending,
  &.-in_progress,
  &.-face_review,
  &.-under_review,
  &.-open {
    color: $status-orange-color;
  }
  &.-cancelled,
  &.-cancelled_pending,
  &.-dormant,
  &.-inoperative,
  &.-returned,
  &.-deleted,
  &.-closed {
    color: $status-gray-color;
  }
  &.-debit_block,
  &.-schedule_failed,
  &.-failed,
  &.-fail,
  &.-failure,
  &.-blocked {
    color: $status-red-color;
  }
  &.-flagged,
  &.-rejected,
  &.-kyc_reject,
  &.-kyc_rejected,
  &.-inactive {
    color: $rejected-declined-labels-color;
  }

  &.-expired {
    color: $table-headers-color;
  }

  &.-invited,
  &.-submitted,
  &.-draft {
    color: $draft-color;
  }
}
